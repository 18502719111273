import React, { useState, useEffect } from 'react';
import Helmet from "react-helmet";

function createMarkup(html) { return {__html: html}; };

let htm = ``

let Spotify = {};

function SpotifyPlayer(props) {
  let token = props.token;
  window.onSpotifyWebPlaybackSDKReady = () => {
    //const token = token;
    console.log("Spotify: " + Spotify);

      const player = new Spotify.Player({
        name: 'Web Playback SDK Quick Start Player',
        getOAuthToken: cb => { cb(token); }
      });

    // Error handling
    player.addListener('initialization_error', ({ message }) => { console.error(message); });
    player.addListener('authentication_error', ({ message }) => { console.error(message); });
    player.addListener('account_error', ({ message }) => { console.error(message); });
    player.addListener('playback_error', ({ message }) => { console.error(message); });
  
    // Playback status updates
    player.addListener('player_state_changed', state => { console.log(state); });
  
    // Ready
    player.addListener('ready', ({ device_id }) => {
      console.log('Ready with Device ID', device_id);
    });
  
    // Not Ready
    player.addListener('not_ready', ({ device_id }) => {
      console.log('Device ID has gone offline', device_id);
    });
  
    // Connect to the player!
    player.connect();
  };
  return(
    <div id="spotify-container">
      <div dangerouslySetInnerHTML={createMarkup(htm)} />
        <Helmet>
          <script src="https://sdk.scdn.co/spotify-player.js" type="module"></script>
        </Helmet>
    </div>
  )
}

export default SpotifyPlayer;
