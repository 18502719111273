import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import './player.css';
import PlayerButton from "./playerButton";
import './playerButton.css';
import SpotifyPlayer from "./spotify";

const Nowplaying = styled.section`
  /* Adapt the colors based on primary prop */
  // background: ${props => props.primary ? "palevioletred" : "white"};
  // color: ${props => props.primary ? "white" : "palevioletred"};

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  // border: 2px solid palevioletred;
  // border-radius: 3px;

  background: DimGray;
  height: 14vh;
  width: calc(20vw);
`;

const Settings = styled.aside`
  /* Adapt the colors based on primary prop */
  // background: ${props => props.primary ? "palevioletred" : "white"};
  // color: ${props => props.primary ? "white" : "palevioletred"};

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  // border: 2px solid palevioletred;
  // border-radius: 3px;

  background: SlateGray;
  height: calc(25pt + 12vh);
  width: calc(20vw);
`;

const PlayerFooter = styled.footer`
  font-size: 0.9em;
  margin: 1em;
  padding: 0.25em 1em;
  // border: 2px solid palevioletred;
  // border-radius: 3px;

  background: Bisque;
  height: calc(15pt + 7vh);
  width: calc(20vw);
  color: black;
`;

const redirect_uri = encodeURIComponent("https://songbird.pacificio.com/");

function Player(props) {

  console.log(document.URL);

  //  https://songbird.pacificio.com/#access_token=BQCmqay-zafjSRiL6FHcbP8MvyIJlAl6LBc1gMt9iwnEnvrbXlop3U9f2valxpZmDUBAbmTwaZVMW93bx9mJggnEk7Jqa6yk84vTnHzcz7r32kY2oxybvbFH2eO9DCU8iKHCCNCJSg6h3Fb6LdaQ0a_t7qrdJ2p9&token_type=Bearer&expires_in=3600&state=123

  const [token, setToken] = useState('');
  const [clientID, setClientID] = useState('ac6efb844dba4c4cb7696aa6c94bdc6d');
  const [encodedRedirectURL, setEncodedRedirectURL] = useState(redirect_uri);
  const [yourInfo, setYourInfo] = useState({});
  const [display_name, setDisplayName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    extractTokenFromURL();
  }, []);

  function viewInfo() {
    extractTokenFromURL();
    //alert("For now, please play music on your home speaker or phone thx")
    //  let dest = 'https://api.spotify.com/track/4kxAgnWw4GKmqcuUiXn60o';
    let dest = 'https://api.spotify.com/v1/me';
    fetch(dest, {
      method: "get", headers: {
        'Authorization': 'Bearer ' + token
      },
    })
      .then(function (response) {
        if (response.ok) {
          console.log("response ok");
          return response.json();
        } else {
          throw new Error(response.Error);
        }
      })
      .then(function (myReturn) {
        console.log(myReturn);
        setYourInfo(myReturn);
        setDisplayName(myReturn.display_name);
        setEmail(myReturn.email);
      });
  }

  function extractTokenFromURL() {
    let str = document.URL;
    var extract0 = str.match(/#access_token=(.*)&token_type=Bearer/);
    if (extract0 !== null) {
      let extract = extract0.pop();
      console.log("extracted token: " + extract);
      setToken(extract);
    }
  }

  let spotifyAuthString = "https://accounts.spotify.com/authorize?client_id=" + clientID + "&redirect_uri=" + encodedRedirectURL + "&scope=user-read-private%20user-read-email&response_type=token&state=123";

  if (token !== '') {
    return(
      <div id="playerContainer">
      <Settings>
        <div id="spotify">
          <a href={spotifyAuthString}><button>auth @ Spotify</button></a>
        </div>
        <div id="info">
          <span>Your Info: {display_name}{"  "}{email}</span>
        </div>
      </Settings>
      <Nowplaying>
        <div id="buttonContainer">
          <span id="playButtonSpan"><button id="playerButton" onClick={viewInfo}>VIEW INFO</button></span>
        </div>
      </Nowplaying>
      <Nowplaying>
        <SpotifyPlayer token={token} />
      </Nowplaying>
    </div>
    )
  }
  else {
    return (
      <div id="playerContainer">
        <Settings>
          <div id="spotify">
            <a href={spotifyAuthString}><button>auth @ Spotify</button></a>
          </div>
          <div id="info">
            <span>Your Info: {display_name}{"  "}{email}</span>
          </div>
        </Settings>
        <Nowplaying>
          <div id="buttonContainer">
            <span id="playButtonSpan"><button id="playerButton" onClick={viewInfo}>VIEW INFO</button></span>
          </div>
        </Nowplaying>
        <PlayerFooter>
          Songbird is a Spotify API app from <a href="https://danmckeown.info">Dan McKeown</a>
        </PlayerFooter>
      </div>
    )
  }
}

export default Player;