import React from 'react';
// import logo from './logo.svg';
// import './App.css';
import PlayButton from "./playerButton";
import Player from "./player";

function App() {
  return (
    <div className="App">
      <Player />
    </div>
  );
}

export default App;
