import React from 'react';
import './playerButton.css';

function playerButton() {

  function play() {
    alert("For now, please play music on your home speaker or phone thx")
  }

  return(
    <div id="buttonContainer">
      <span id="playButtonSpan"><button id="playerButton" onClick={play}>PLAY MUSIC</button></span>
    </div>
  )
}

export default playerButton;